import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `primary`
  },
  occupation: {
    mb: 4
  },
  specialty: {
    color: `text`,
    mb: 4
  }
}

export default () => (
  <>
    <Heading variant='h1'>
      尼森的科技哲學
    </Heading>
    <Heading variant='h3' sx={styles.specialty}>
      Philosophy of technology
    </Heading>
    <Box variant='buttons.group'>
      {/* <Button as={Link} to='/contact'>
        Contact Me
      </Button> */}
      <Button as={Link} to='/about'>
        About
      </Button>
    </Box>
  </>
)
