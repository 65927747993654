import React from 'react'
import { Link } from 'gatsby'
import { Card, Badge, Box } from 'theme-ui'
import Section from '@components/Section'
import { useBlogTags } from '@helpers'

const Tags = props => {
  const tags = useBlogTags()

  return (
    <Section aside title='Tags' {...props}>
      <Card variant='paper'>
        <Box variant='lists.badges'>
          {tags.map(({ id, name, slug }) => (
            <Badge variant='tag' key={id} as={Link} to={slug}>
              {name}
            </Badge>
          ))}
        </Box>
      </Card>
    </Section>
  )
}

export default Tags
