import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'
import Navigation from '@components/Navigation'
import { useSiteConfig, mapSocialIcons } from '@helpers'

const Social = () => {
  const { social } = useSiteConfig()

  return (
    <Section aside title="Let's Talk">
      <Card variant='paper'>
        <Text variant='p'>
          Want to share anything interesting with me ?
          Let's talk.
        </Text>
        <Navigation items={mapSocialIcons(social)} iconOnly />
      </Card>
    </Section>
  )
}

export default Social
